import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, catchError, Observable, of, switchMap, take, throwError} from 'rxjs';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {environment} from '../../../environments/environment';
import {LocationStrategy} from '@angular/common';
import {REMOVE_SESSION} from "../../modules/admin/users/users.query";
import {map} from "rxjs/operators";
import {Apollo} from "apollo-angular";

@Injectable({providedIn: 'root'})
export class AuthService {

    private _authenticated: boolean = false;
    private loginUri: any;
    private apiPublic: any;
    private appUrl: any;


    private _routesPermissions = new BehaviorSubject<any>(null);
    public routesPermissions$ = this._routesPermissions.asObservable();

    // Method to update routesPermissions
    setRoutesPermissions(value: any) {
        this._routesPermissions.next(value);
    }

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private locationStrategy: LocationStrategy, private _apollo: Apollo) {
        this.loginUri = environment.apiLogin;
        this.apiPublic = environment.apiPublic;
        this.appUrl = environment.appUrl;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get accessToken(): string {
        return localStorage.getItem('access_token');
    }

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('access_token', token);
    }

    get currentUserId(): string {
        return localStorage.getItem('current_user');
    }

    set currentUserId(currentUserId: string) {
        localStorage.setItem('current_user', currentUserId);
    }

    get userFirstName(): string {
        return localStorage.getItem('current_user_firstname');
    }

    set userFirstName(userFirstName: string) {
        localStorage.setItem('current_user_firstname', userFirstName);
    }

    get userLastName(): string {
        return localStorage.getItem('current_user_lastname');
    }

    set userLastName(userLastName: string) {
        localStorage.setItem('current_user_lastname', userLastName);
    }

    get userEmail(): string {
        return localStorage.getItem('current_user_email');
    }

    set userEmail(userEmail: string) {
        localStorage.setItem('current_user_email', userEmail);
    }

    get refreshToken(): string {
        return localStorage.getItem('refreshToken') ?? '';
    }

    /**
     * Setter & getter for refresh token
     */
    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }

    get tenant(): string {
        return localStorage.getItem('current_tenant');
    }

    set tenant(tenant: string) {
        localStorage.setItem('current_tenant', tenant);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        const body = {
            email: email,
        };
        return this._httpClient.post(this.apiPublic + '/reset-password', body, { headers: headers });
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials?: { email: string; password: string, rememberMe: boolean }, code?): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }
        const httpOptions = {
            headers: new HttpHeaders({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'Content-Type': 'application/x-www-form-urlencoded',
            })
        };
        const body = new URLSearchParams();
        body.set('client_id', 'app');
        if (credentials) {
            body.set('username', credentials.email);
            body.set('password', credentials.password);
            body.set('grant_type', 'password');
            body.set('client_secret', environment.clientSecret);
            body.set('remember_me', credentials.rememberMe.toString());
        } else {
            body.set('grant_type', 'authorization_code');
            body.set('code', code);
            body.set('redirect_uri', this.appUrl + '/reset-password');
        }
        return this._httpClient.post(this.loginUri + '/realms/' + environment.realm + '/protocol/openid-connect/token',
            body.toString(), httpOptions).pipe(switchMap((response: any) => {

            this.accessToken = response.access_token;
            this.refreshToken = response.refresh_token;
            const meta = AuthUtils.getUserMeta(response.access_token);
            this.userLastName = meta.lastName;
            this.userFirstName = meta.firstName;
            this.userEmail = meta.email;
            this.currentUserId = meta.id;
            this.tenant = meta.tenant;
            // Set the authenticated flag to true
            this._authenticated = true;
            this.locationStrategy.getBaseHref();

            // Return a new observable with the response
            return of(response);
        }));
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {

        if (this.accessToken === undefined) {
            console.log('error');
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
            })
        };

        const body = new URLSearchParams();
        body.set('client_id', 'app');
        body.set('grant_type', 'refresh_token');
        body.set('refresh_token', this.refreshToken);

        // Sign in using the token
        return this._httpClient.post(this.loginUri + '/realms/' + environment.realm + '/protocol/openid-connect/token', body, httpOptions).pipe(catchError(() => // Return false
            of(false)), switchMap((response: any) => {
            // Store the access token in the local storage
            this.accessToken = response.access_token;
            this.refreshToken = response.refresh_token;

            // Set the authenticated flag to true
            this._authenticated = true;

            // Return true
            return of(true);
        }));
    }

    hasRoles(accessRoles): boolean {
        const claims = AuthUtils.getUserMeta(this.accessToken);
        const actualRoles: string[] = accessRoles;
        if (actualRoles?.length > 0 && !!claims.roles) {
            return actualRoles.some(r => claims.roles.includes(r));
        } else {
            return false;
        }
    }



    /**
     * Sign out
     */
    signOut(): Observable<any> {
        return this._apollo
            .mutate<{ removeUserSession: boolean }>({
                mutation: REMOVE_SESSION,
                fetchPolicy: 'no-cache'
            }).pipe(
                switchMap((response: any) => {
                    // Store the access token in the local storage
                    localStorage.clear();
                    // Set the authenticated flag to false
                    this._authenticated = false;

                    // Return true
                    return of(false);
                }),
                catchError((err) => {
                    localStorage.clear();
                    // Set the authenticated flag to false
                    this._authenticated = false;
                    return of(false);
                })
            );


    }


    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return;
        /*return this._httpClient.post('api/auth/unlock-session', credentials);*/
    }

    isLoggedIn(): boolean {
        return this.accessToken ? true : false;
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in and the access token is not expired
        if (this._authenticated && !AuthUtils.isTokenExpired(this.accessToken)) {
            return of(true);
        }

        // If the user is logged in but the access token is expired, sign in using refresh token
        if (this._authenticated && AuthUtils.isTokenExpired(this.accessToken)) {
            return this.signInUsingToken();
        }
        // Check the access token expire date
        if (this.refreshToken && AuthUtils.isTokenExpired(this.accessToken)) {
            // If the access token is expired, sign in using refresh token
            return this.signInUsingToken();
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            // If the access token is expired, sign in using refresh token
            return this.signInUsingToken();
        }

        // If the access token exists and is not expired, return true
        return of(true);
    }


    getAuthorizedRoles(resource: string, privilege: string): string[] {
        let authorizedRoles: string[] = [];
        this.routesPermissions$.pipe(
            take(1),
        ).subscribe(value => {
            if (value && value[resource] && value[resource][privilege]) {
                authorizedRoles = value[resource][privilege];
            }
        });
        return authorizedRoles;
    }

    getUserRole(): string {
        const userData = AuthUtils.getUserMeta(this.accessToken);
        const stringsToRemove = ["offline_access", "uma_authorization", "default-roles-app"];
        return userData.roles.filter(role => !stringsToRemove.includes(role))[0];
    }
}
