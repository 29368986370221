import gql from 'graphql-tag';

export const findCurrentResources = gql`
    query {
        findCurrentResources {
            resource
            privileges
        }
    }
`;
