import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding, HostListener,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocomplete, MatAutocompleteModule} from '@angular/material/autocomplete';
import {debounceTime, filter, map, Subject, takeUntil} from 'rxjs';
import {fuseAnimations} from '@fuse/animations/public-api';
import {SearchService} from '../../../shared/services/search.service';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {Overlay} from "@angular/cdk/overlay";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {Platform} from "@angular/cdk/platform";
import {AnimationOptions, LottieComponent} from "ngx-lottie";
import {MatListModule} from "@angular/material/list";
import {FlowbotDialogComponent} from "../../../shared/components/dialogs/flowbot-dialog/flowbot-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'fuseSearch',
    animations: fuseAnimations,
    standalone: true,
    providers: [
        {
            provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useFactory: () => {
                const overlay = inject(Overlay);
                return () => overlay.scrollStrategies.block();
            },
        },
    ],
    imports: [
        NgIf,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        RouterLink,
        NgClass,
        NgForOf,
        LottieComponent,
        MatListModule
    ]
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy {
    @Input() appearance: 'basic' | 'bar' = 'basic';
    @Input() debounce: number = 300;
    @Input() minLength: number = 3;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();
    @Output() drawerToggle: EventEmitter<string> = new EventEmitter<string>();
    options: AnimationOptions = {
        path: '/assets/lottie/flowbot-logo-animated.json',
    };
    opened: boolean = false;
    resultSets: any[];
    searchControl: UntypedFormControl = new UntypedFormControl();
    isMac: boolean;
    currentSearchValue;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _searchService: SearchService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private el: ElementRef,
        private platform: Platform,
        public dialog: MatDialog,
    ) {
        this.isMac = this.platform.IOS || this.platform.SAFARI;
    }

    private _matAutocomplete: MatAutocomplete;

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter for mat-autocomplete element reference
     *
     * @param value
     */
    @ViewChild('matAutocomplete')
    set matAutocomplete(value: MatAutocomplete) {
        this._matAutocomplete = value;
    }

    @ViewChild('barSearchInput', { static: false }) barSearchInputElementRef: ElementRef<HTMLInputElement>;

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any {
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'search-appearance-bar': this.appearance === 'bar',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'search-appearance-basic': this.appearance === 'basic',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'search-opened': this.opened
        };
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        // Check for Ctrl + K or Cmd + K
        if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
            event.preventDefault(); // Prevent the default action of the event
            this.open();
        }
        if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
            event.preventDefault(); // Prevent the default action of the event
            this.drawerToggle.emit(null);
        }
    }

    /**
     * Setter for bar search input
     *
     * @param value
     */
    @ViewChild('barSearchInput')
    set barSearchInput(value: ElementRef) {
        // If the value exists, it means that the search input
        // is now in the DOM, and we can focus on the input..
        if (value) {
            // Give Angular time to complete the change detection cycle
            setTimeout(() => {
                console.log('set')
                // Focus to the input element
                value.nativeElement.focus();
            });
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        // Appearance
        if ('appearance' in changes) {
            // To prevent any issues, close the
            // search after changing the appearance
            this.close();
        }
    }

    /**
     * On init
     */
    ngOnInit(): void {

        // Subscribe to the search field value changes
        this.searchControl.valueChanges
            .pipe(
                debounceTime(this.debounce),
                takeUntil(this._unsubscribeAll),
                map((value) => {

                    // Set the resultSets to null if there is no value or
                    // the length of the value is smaller than the minLength
                    // so the autocomplete panel can be closed
                    if (!value || value.length < this.minLength) {
                        this.resultSets = null;
                    }

                    // Continue
                    return value;
                }),
                // Filter out undefined/null/false statements and also
                // filter out the values that are smaller than minLength
                filter(value => value && value.length >= this.minLength)
            )
            .subscribe((value) => {
                this.currentSearchValue = '';
                this.currentSearchValue = value;
                this._searchService.siteSearch(value.trim())
                    .subscribe((resultSets: any) => {

                        // Store the result sets
                        this.resultSets = resultSets;

                        // Execute the event
                        this.search.next(resultSets);
                    });
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On keydown of the search input
     *
     * @param event
     */
    onKeydown(event: KeyboardEvent): void {
        // Escape
        if (event.code === 'Escape') {
            // If the appearance is 'bar' and the mat-autocomplete is not open, close the search
            if (this.appearance === 'bar' && !this._matAutocomplete.isOpen) {
                this.close();
            }
        }
        if(event.code === 'Enter'){
            this.openFlowBotDialog();
        }
    }

    /**
     * Open the search
     * Used in 'bar'
     */
    open(): void {
        // Return if it's already opened
        if (this.opened) {
            return;
        }

        // Open the search
        this.opened = true;
    }

    /**
     * Close the search
     * * Used in 'bar'
     */
    close(): void {
        // Return if it's already closed
        if (!this.opened) {
            return;
        }
        this.searchControl.setValue('');



        // Close the search
        this.opened = false;
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    routeToSelected(event): void {
        const currentRoute = this._activatedRoute.snapshot;

        // @ts-ignore
        if (currentRoute._routerState.url.startsWith('/' + event?.link)) {
            this._router.navigateByUrl('/blank', {skipLocationChange: true}).then(() => {
                if (event?.type?.toLowerCase() === 'invoice') {
                    this._router.navigate([event?.link + '/list/' + event?.linkId], {queryParams: {invoiceId: event?.id}});
                } else {
                    this._router.navigate([event?.link + '/list/' + event?.id]);
                }
            });
        } else {
            if (event?.type?.toLowerCase() === 'invoice') {
                this._router.navigate([event?.link + '/list/' + event?.linkId], {queryParams: {invoiceId: event?.id}});
            } else {
                this._router.navigate([event?.link + '/list/' + event?.id]);
            }
        }


    }

    openFlowBotDialog() {
        this.drawerToggle.emit(this.currentSearchValue);
        // If the appearance is 'bar' and the mat-autocomplete is not open, close the search
        this.close();

    }
}
