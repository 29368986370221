import {Customers, SetCustomerId} from "../customers/customer.type";
import {Factories, SetFactoryId} from "../factories/factory.type";

export interface User {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    isAdministrator: boolean;
    isInside: boolean;
    isOutside: boolean;
    username: string;
    role?: string;
    status?: boolean;
}
export interface UserUpdateWithOutPassword{
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    avatar: string;
    status: string;
    type:  string;
    username:  string;
    title:  string;
    about:  string;
    phone:  string;
}

export interface SetUserId{
    id: string;
}

export interface SetSalesRepId{
    id: string;
}


export type SalesRepSelection = {
    id: string;
    customer?: Customers;
    factory?: Factories;
    createDate: string;
    createdBy: User;
    customerSelectionType: CustomerSelectionType;
    outsideRepSplit?: OutsideRepSplit[];
};

export type OutsideRepSplit = {
    id: string;
    user: User;
    splitRate: number;
    createDate: string;
    createdBy: User;
    salesRepSelection: SalesRepSelection;
};

export interface SalesRepSelectionInput {
    id: string;
    customer: SetCustomerId;
    factory: SetFactoryId;
    customerSelectionType: CustomerSelectionType;
    outsideRepSplit: OutsideRepSplitInput[];
};

export interface OutsideRepSplitInput {
    id: string;
    user: SetUserId;
    splitRate: number;
};

export enum CustomerSelectionType {
    EndUser = "endUser",
    Customer = "customer",
}

export interface Privilege {
    privilege: string;
    privilegeId?: number;
    option?: string;
}

export interface Role {
    [key: string]: number[];
}

export interface RoleList {
    role: string;
    permissionType: string;
}

export interface PermissionByResource {
    resource: string;
    roles: RolesPrivilege[];
}

export interface CurrentUserPermission {
    resource: string;
    privileges: string[];
}

export interface RolesPrivilege {
    role: string
    permissionType?: string;
    privileges: Privilege[];
}

export interface UserInput{
    id: string;
    firstName:string;
    lastName:string;
    email:string;
    password:string;
    isOutside: boolean;
    isInside: boolean;
    username: string;
    status: boolean;
    resetPasswordEmail?: boolean;
}

export interface MinifiedPermissions {
    [key: string]: MinifiedPermissionsActions;
}

export interface MinifiedPermissionsActions {
    [key: string]: string[];
}
export interface roleTypes {
    label: string;
    value: string;
    description: string;
    customizable: boolean;
}

export const roles: roleTypes[] = [
    {
        label: 'Inside Rep',
        value: 'inside-rep',
        description: 'Inside Reps will be available for selection in the inside rep allocation lists.',
        customizable: true,
    },
    {
        label: 'Outside Rep',
        value: 'outside-rep',
        description: 'Outside Reps will be available for selection in the outside rep lists and be available for commission allocation.',
        customizable: true,
    },
    {
        label: 'Administrator',
        value: 'administrator',
        description: 'This user type has full access to the site aside from pages and functions reserved for owners only.  This role cannot be customized.',
        customizable: false,
    },
    {
        label: 'Manager',
        value: 'manager',
        description: 'Managers have elevated access by default, but can be customized to suit your needs.',
        customizable: true,
    },
    {
        label: 'Accountant',
        value: 'accountant',
        description: 'Accountants only have access to checks and invoices. This is a special role and it cannot be customized.',
        customizable: false,
    },
    {
        label: 'Owner',
        value: 'owner',
        description: 'This role is automatically assigned to the user that signed up for the site.  They have full access to everything and the role cannot be customized.',
        customizable: false,
    },
];
