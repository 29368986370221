
import { NavigationMockApi } from 'app/mock-api/common/navigation/api';

import { SearchMockApi } from 'app/mock-api/common/search/api';


export const mockApiServices = [

    NavigationMockApi,

    SearchMockApi,

];
