import { gql } from 'apollo-angular';

export const notificationFragment = gql`
    fragment NotificationFields on NotifierSettings {
        alertType
        visibility
        location
    }
`;

export const FIND_BY_ID = gql`
    query findById($uuid: ID!) {
        findById(uuid: $uuid) {
            uuid
            companyName
            logo
            streetAddress
            addressTwo
            city
            state
            zip
            email
            phone
            createDate
        }
    }
`;

export const FIND_SITE_SETTINGS = gql`
    query findSiteSettings {
        findSiteSettings {
            uuid
            companyName
            logo
            streetAddress
            addressTwo
            city
            state
            zip
            email
            phone
            createDate
        }
    }
`;

export const UPDATE_SITE_SETTINGS_BY_ID = gql`
    mutation updateSiteSettingsById($siteSettings: SiteSettingsInput!) {
        updateSiteSettingsById(siteSettings: $siteSettings) {
            uuid
            companyName
            logo
            streetAddress
            addressTwo
            city
            state
            zip
            email
            phone
        }
    }
`;

export const FIND_FIRST_EMAIL_SETTING = gql`
    query findFirstEmailSetting {
        findFirstEmailSetting {
            uuid
            host
            port
            username
            password
            sendFromName
            smtpAuth
            tlsEnabled
        }
    }
`;

export const UPDATE_EMAIL_SETTING = gql`
    mutation UpdateEmailSetting($input: EmailSettingsInput!) {
        updateEmailSetting(input: $input) {
            uuid
            host
            port
            username
            password
            sendFromName
            smtpAuth
            tlsEnabled
        }
    }
`;

export const FIND_FIRST_AUTO_GENERATED_SETTING = gql`
    query FindFirstAutoGeneratedSetting {
        findFirstAutoGeneratedSetting {
            uuid
            prefix
            startsAt
            counter
            type
        }
    }
`;

export const UPDATE_AUTO_GENERATED_SETTING = gql`
  mutation UpdateAutoGeneratedSetting($input: AutoGeneratedSettingsInput!) {
    updateAutoGeneratedSetting(input: $input) {
      uuid
      prefix
      startsAt
      counter
      type
    }
  }
`;

export const updateNotifierSettings = gql`
    mutation updateNotifierSettings($userId: String!, $notifiers: [NotifierInput]) {
        updateNotifierSettings(userId: $userId, notifiers: $notifiers)
    }
`;

export const findNotifierSettingsByUserId = gql`
    query findNotifierSettingsByUserId($userId: String!) {
        findNotifierSettingsByUserId(userId: $userId) {
            ...NotificationFields
        }
    }
    ${notificationFragment}
`;

export const siteOptionsFragment = gql`
    fragment SiteOptionsFields on SiteOptions{
        uuid
        key
        value
    }
`;

export const findSiteOptions = gql`
    query findSiteOptions($key: String) {
        findSiteOptions(key: $key){
            ...SiteOptionsFields
        }
    }
    ${siteOptionsFragment}
`;

export const saveSiteOptions = gql`
    mutation saveSiteOptions($siteOptions: SiteOptionsInput){
        saveSiteOptions(siteOptions: $siteOptions){
            ...SiteOptionsFields
        }
    }
    ${siteOptionsFragment}
`;

export const updateSiteOptions = gql`
    mutation updateSiteOptions($siteOptions: SiteOptionsInput){
        updateSiteOptions(siteOptions: $siteOptions){
            ...SiteOptionsFields
        }
    }
    ${siteOptionsFragment}
`;

export const saveLostReason = gql`
    mutation saveLostReason($input: LostReasonsInput!){
        saveLostReason(input: $input){
            uuid
            title
        }
    }
`;

export const deleteLostReason = gql`
    mutation ($id: ID!) {
        deleteLostReason(id: $id)
    }`;
