import {Injectable} from '@angular/core';
import {SiteWideSearch} from '../types/search.type';
import {Observable, of, switchMap} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {siteWideSearch} from '../queries/search.query';
import {Apollo} from "apollo-angular";
import {HttpClient, HttpHeaders} from "@angular/common/http";


@Injectable({
    providedIn: 'root'
})
export class SearchService {


    private _search: BehaviorSubject<SiteWideSearch>;

    constructor(
        private apollo: Apollo,
        private _httpClient: HttpClient
    ) {
        this._search = new BehaviorSubject(null);
    }

    siteSearch(searchString: string): Observable<SiteWideSearch> {
        return this.apollo.query<{ siteWideSearch: SiteWideSearch }>({
            query: siteWideSearch,
            variables: {
                'searchString': searchString,
            }, fetchPolicy: 'no-cache'
        }).pipe(map((result: any) => result.data.siteWideSearch),
            tap((search) => {
                this._search.next(search);
            }));
    }
    postChatMessage(text: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json', 'charset': 'UTF-8',
                'Tenant': localStorage.getItem('current_tenant'),
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            })
        };
        const payload = {
            query: text,
            k: 20,
            max_tokens: 2000,
        };

        return this._httpClient.post(`https://staging.ai.flowrms.com/vector/query`, JSON.stringify(payload), httpOptions).pipe(
            switchMap((response: any) => {

                return of(response);
            }));
    }



}
