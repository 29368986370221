import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { initialDataResolver } from 'app/app.resolvers';


export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes').then(m => m.default)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes').then(m => m.default)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes').then(m => m.default)},
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },

        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes').then(m => m.default)},
        ]
    },

    // Admin routes
    {
        path       : '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: initialDataResolver,
        },
        children   : [


            {
                path: 'reports',
                loadChildren: () => import('app/modules/admin/reports/reports.routes').then(m => m.reportsRoutes)
            },
            { path: 'admin', loadChildren: () => import('app/modules/admin/admin-settings/admin-settings.routes').then(m => m.default) },
            {
                path: 'uploads',
                loadChildren: () => import('app/modules/admin/uploads/uploads.routes').then(m => m.uploadsRoutes)
            },
            {
                path: 'dashboard',
                loadChildren: () => import('app/modules/admin/dashboards/finance/finance.routes').then(m => m.financeRoutes)
            },
            {
                path: 'rep-dashboard',
                loadChildren: () => import('app/modules/admin/dashboards/outside-rep-dashboard/outside-rep-dashboard.routes').then(m => m.outsideRepDashboardRoutes)
            },
            {
                path: 'ar-dashboard',
                loadChildren: () => import('app/modules/admin/dashboards/ar-dashboard/ar-dashboard.routes').then(m => m.arDashboardRoutes)
            },
            // Invoices
            {
                path: 'invoices', children: [
                    { path: 'list', loadChildren: () => import('app/modules/admin/invoices/invoices.routes').then(m => m.invoicesRoutes) },
                ]
            },
            // Checks
            {
                path: 'checks', children: [
                    { path: '', loadChildren: () => import('app/modules/admin/checks/checks.routes').then(m => m.checksRoutes) },
                ]
            },
            // Orders
            {
                path: 'orders', children: [
                    { path: 'list', loadChildren: () => import('app/modules/admin/orders/orders.routes').then(m => m.ordersRoutes) },
                ]
            },
            // Quotes
            {
                path: 'quotes', children: [

                    {path: 'list', loadChildren: () => import('app/modules/admin/quotes/quotes.routes').then(m => m.quotesRoutes)},

                ]
            },
            // Customers
            {
                path: 'customers', children: [

                    { path: 'list', loadChildren: () => import('app/modules/admin/customers/customers.routes').then(m => m.customerRoutes) },
                ]
            },
            // Factories
            {
                path: 'factories', children: [
                    { path: 'list', loadChildren: () => import('app/modules/admin/factories/factories.routes').then(m => m.factoriesRoutes)},
                ]
            },
            // Products
            {
                path: 'products',
                children: [
                    { path: '', loadChildren: () => import('app/modules/admin/products/products.routes').then(m => m.productRoutes) }
                ]
            },
            // Blank route for search workaround
            {
                path: 'blank',
                children: [
                    { path: '', loadChildren: () => import('app/modules/admin/blank-route/blank-route.routes').then(m => m.blankRoutes) }
                ]
            },
            // Users
            {
                path: 'user',
                children: [
                    {
                        path: 'settings',
                        loadChildren: () => import('app/modules/admin/users/settings/settings.routes').then(m => m.default)
                    },
                ]
            },
            {path: 'export-pdf', loadChildren: () => import('app/modules/admin/pdf-export/pdf-export.routes').then(m => m.pdfExportRoutes)},
            {path: '503', loadChildren: () => import('app/modules/admin/pages/errors/error-500/error-500.routes').then(m => m.error500Routes)},
            // Changelog route
            { path: 'changelog', loadChildren: () => import('app/modules/admin/changelog/changelog.routes').then(m => m.changelogRoutes) },
            // Wrong route
            { path: '**', loadChildren: () => import('app/modules/admin/pages/errors/error-404/error-404.routes').then(m => m.error404Routes)},

        ]
    }
];
