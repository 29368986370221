import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHandlerFn, HttpRequest} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import {catchError, Observable, switchMap, throwError} from 'rxjs';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);

    // Clone the request object
    let newReq = req.clone();

    if (authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken)) {
        newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken),
        });
    }

    // Response
    return next(newReq).pipe(
        catchError((error) => {
            // Catch "401 Unauthorized" responses
            if (error instanceof HttpErrorResponse && error.status === 401) {
                // Check if there's a valid refresh token
                if (authService.refreshToken && !AuthUtils.isTokenExpired(authService.refreshToken)) {
                    // Attempt to refresh the token
                    return authService.signInUsingToken().pipe(
                        switchMap(() => {
                            // Retry the request with the new token
                            const newReqWithNewToken = req.clone({
                                headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken),
                            });
                            return next(newReqWithNewToken);
                        }),
                        catchError((refreshError) => {
                            // If the refresh token request fails, sign out and reload the app
                            authService.signOut();
                            return throwError(refreshError);
                        })
                    );
                } else {
                    // No valid refresh token, sign out
                    authService.signOut();
                }
            }

            return throwError(error);
        }),
    );
};

