<!-- Bar search -->
<ng-container *ngIf="appearance === 'bar'">
    <div class="rounded border-accent-200 border px-2 pb-.5 pt-1">
        <button class="bg-transparent"
            *ngIf="!opened"
            (click)="open()" [matTooltip]="'Site Wide Search'">
        <span class="flex flex-row gap-16 items-center">
            <span class="flex flex-row gap-2 items-center text-secondary">
                <mat-icon [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
                Search flowRMS...
            </span>
            <span class="flex text-xs text-secondary font-bold leading-tight bg-gray-100 rounded px-2 py-1">
                <span *ngIf="!isMac">ctrl K</span>
                <span *ngIf="isMac">
                    ⌘ K
                </span>
            </span>

        </span>
        </button>
    </div>
    <div
        class="absolute inset-0 flex items-center shrink-0 z-99 bg-card"
        *ngIf="opened"
        @slideInTop
        @slideOutTop>
        <mat-icon
            class="absolute ml-6 sm:ml-8"
            [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
        <input
            class="w-full h-full px-16 sm:px-18"
            [formControl]="searchControl"
            [matAutocomplete]="matAutocomplete"
            [placeholder]="'Search flowRMS...'"
            (keydown)="onKeydown($event)"
            #barSearchInput>
        <mat-autocomplete
            class="max-h-128 sm:px-2 border-t rounded-b shadow-md"
            [autoSelectActiveOption]="true"
            [disableRipple]="true"
            #matAutocomplete="matAutocomplete">
            <mat-option *ngIf="searchControl.value" (click)="openFlowBotDialog();">
                <span class="py-0 px-6 text-md pointer-events-none text-secondary group relative mb-1 rounded-md hover:bg-gray-100 flex flex-row content-center items-center gap-2">
                    <ng-lottie class="flex justify-center" width="28px" [options]="options"></ng-lottie>
                    <span>Ask flowBot: {{ searchControl.value }}</span>
                    <span class="flex text-xs text-secondary font-bold leading-tight bg-gray-100 rounded px-2 py-1">
                <span>
                    Enter
                </span>
            </span>
                </span>
            </mat-option>
            <mat-divider></mat-divider>
            <mat-option
                class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                *ngIf="resultSets && !resultSets.length">
                No Results for the given query. Try your search again, or ask flowBot to help.
            </mat-option>

            <ng-container *ngFor="let result of resultSets; trackBy: trackByFn">
                    <mat-option
                        (click)="routeToSelected(result); close();"
                        [value]="result.id" >
                        <!-- Product result -->
                        <div *ngIf="result.type === 'products'"
                             class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover flex flex-row content-center items-center">
                            <div class="bg-purple-900 rounded h-4 px-2 mr-2 text-white items-center flex content-center text-xs">Product</div>
                            <div class="title">
                                <span [innerHTML]="result.title"></span>
                            </div>
                        </div>
                        <!-- Contact result -->
                        <div *ngIf="result.type === 'Quote'"
                             class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover flex flex-row content-center items-center">
                            <div class="bg-blue-700 rounded h-4 px-2 mr-2 text-white items-center flex content-center text-xs">Quote</div>
                            <div class="title">
                                <span [innerHTML]="result.title"></span>
                            </div>
                        </div>
                        <div *ngIf="result.type === 'Order'"
                             class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover flex flex-row content-center items-center">
                            <div class="bg-green-900 rounded h-4 px-2 mr-2 text-white items-center flex content-center text-xs">Order</div>
                            <div class="title">
                                <span [innerHTML]="result.title"></span>
                            </div>
                        </div>
                        <div *ngIf="result.type === 'Invoice'"
                             class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover flex flex-row content-center items-center">
                            <div class="bg-yellow-500 rounded h-4 px-2 mr-2 text-white items-center flex content-center text-xs">Invoice</div>
                            <div class="title">
                                <span [innerHTML]="result.title"></span>
                            </div>
                        </div>
                        <div *ngIf="result.type === 'Check'"
                             class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover flex flex-row content-center items-center">
                            <div class="bg-orange-500 rounded h-4 px-2 mr-2 text-white items-center flex content-center text-xs">Check</div>
                            <div class="title">
                                <span [innerHTML]="result.title"></span>
                            </div>
                        </div>
                        <div *ngIf="result.type === 'Customer'"
                             class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover flex flex-row content-center items-center">
                            <div class="bg-gray-600 rounded h-4 px-2 mr-2 text-white items-center flex content-center text-xs">Customer</div>
                            <div class="title">
                                <span [innerHTML]="result.title"></span>
                            </div>
                        </div>
                        <div *ngIf="result.type === 'Factory'"
                             class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover flex flex-row content-center items-center">
                            <div class="bg-red-800 rounded h-4 px-2 mr-2 text-white items-center flex content-center text-xs">Factory</div>
                            <div class="title">
                                <span [innerHTML]="result.title"></span>
                            </div>
                        </div>
                        <div *ngIf="result.type === 'Order Acknowledgements'"
                             class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover flex flex-row content-center items-center">
                            <div class="bg-orange-600 rounded h-4 px-2 mr-2 text-white items-center flex content-center text-xs">Order Acknowledgement</div>
                            <div class="title">
                                <span [innerHTML]="result.title"></span>
                            </div>
                        </div>
                        <div *ngIf="result.type === 'Job Name'"
                             class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover flex flex-row content-center items-center">
                            <div class="bg-blue-400 rounded h-4 px-2 mr-2 text-white items-center flex content-center text-xs">Job Name</div>
                            <div class="title">
                                <span [innerHTML]="result.title"></span>
                            </div>
                        </div>
                        <div *ngIf="result.type === 'Customer Part Numbers'"
                             class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover flex flex-row content-center items-center">
                            <div class="bg-purple-400 rounded h-4 px-2 mr-2 text-white items-center flex content-center text-xs">Customer Part Number</div>
                            <div class="title">
                                <span [innerHTML]="result.title"></span>
                            </div>
                        </div>
                    </mat-option>

            </ng-container>
        </mat-autocomplete>
        <button
            class="absolute top-1/2 right-5 sm:right-7 shrink-0 w-10 h-10 -mt-5"
            mat-icon-button
            (click)="close()">
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
</ng-container>
