import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef, EventEmitter, Input, OnChanges,
    OnDestroy,
    OnInit, Output, Sanitizer, SecurityContext, SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from "@angular/material/dialog";
import {HttpClient, HttpDownloadProgressEvent, HttpEvent, HttpEventType, HttpHeaders} from "@angular/common/http";
import {AuthService} from "../../../../core/auth/auth.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CalendarModule} from "primeng/calendar";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {AnimationOptions, LottieComponent} from "ngx-lottie";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {MatBadgeModule} from "@angular/material/badge";
import {VoiceRecognitionService} from "../../../services/voicerecognition.service";
import {MatTooltip} from "@angular/material/tooltip";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatButtonToggle} from "@angular/material/button-toggle";
import {MatSlideToggle} from "@angular/material/slide-toggle";

interface Message {
    id: string
    type: string;
    message: string;
}

@Component({
    selector: 'app-flowbot-dialog',
    standalone: true,
    imports: [CommonModule, FormsModule, CalendarModule, MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatOptionModule, MatSelectModule, ReactiveFormsModule, MatDialogModule, LottieComponent, MatBadgeModule, MatTooltip, MatMenu, MatMenuTrigger, MatButtonToggle, MatMenuItem, MatSlideToggle],
    templateUrl: './flowbot-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class FlowbotDialogComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @ViewChild('inputField') inputField: ElementRef;
    @ViewChild('messagesContainer') private messagesContainer: ElementRef;
    @Output() drawerToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() searchInput: string = null;
    hints: any[] = [
        { id: 1, title: "Learn About Inverters", subTitle: "Show the technical specs of String Inverters" },
        { id: 2, title: "Best Customer", subTitle: "Find out who has made the most purchases this year." },
        { id: 3, title: "Monthly Sales", subTitle: "Get the total sales for the last month." },
        { id: 4, title: "Generate Invoice", subTitle: "Create a new invoice for recent orders." },
        { id: 5, title: "Payment Status", subTitle: "Check the payment status for all outstanding invoices." },
        { id: 6, title: "Order Trends", subTitle: "Analyze order trends over the past quarter." },
        { id: 7, title: "Factory Performance", subTitle: "Review performance metrics for each factory." },
        { id: 8, title: "Revenue Forecast", subTitle: "Forecast revenue based on current order data." },
        { id: 9, title: "Customer Insights", subTitle: "Dive into customer behavior and preferences." },
        { id: 10, title: "Quote Conversion", subTitle: "Track conversion rates from quotes to actual orders." }
    ];
    displayedHints: any[] = [];
    messages: Message[] = [];
    userInput: string = '';
    options: AnimationOptions = {
        path: '/assets/lottie/flowbot-logo-animated.json',
    };
    currentMessageId: string = '';
    currentMessage: string = '';
    lineArrays: number[] = [];
    finalTranscript: string = '';
    interimTranscript: string = '';
    isUserSpeaking: boolean = false;
    userInitials;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private http: HttpClient,
        private sanitizer: Sanitizer,
        private _changeDetectorRef: ChangeDetectorRef,
        private voiceRecognition: VoiceRecognitionService) {
        this._unsubscribeAll = new Subject();
        this.voiceRecognition.initialize((transcript: string, isFinal: boolean) => {
            this.updateUserInput(transcript, isFinal);
        });
        const firstName = localStorage.getItem('current_user_firstname');
        const lastName = localStorage.getItem('current_user_lastname');
        this.userInitials = (firstName ? firstName[0] : '') + (lastName ? lastName[0] : '');
    }

    ngOnInit(): void {
        this.displayedHints = this.shuffleHints(this.hints).slice(0, 4);
    }

    ngAfterViewInit(): void {
        this._changeDetectorRef.detectChanges();
        this.scrollToBottom();
    }
    scrollToBottom(): void {
        setTimeout(() => {
            this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
        }, 0);
    }

    ngOnDestroy(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        this.scrollToBottom();

        if (changes['searchInput']) {
            if(changes['searchInput'].previousValue == undefined && changes['searchInput'].currentValue != null){
                this.searchInput = changes['searchInput'].currentValue;
                this.sendMessage(this.searchInput);
            }

        }
    }

    sendMessage(text?: string): void {
        const messageText = text ? text : this.userInput;

        const randomId = crypto.randomUUID();
        this.messages.push({id: randomId, type: 'user', message: messageText})
        this.chatRequest(messageText);
        this.userInput = '';
    }

    chatRequest(text: string): any {
        this.currentMessageId = '';
        this.currentMessage = '';
        this.lineArrays = [];
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',

            'Tenant': 'nts'
        });
        let payload = {
            query: text,
            k: 20,
            max_tokens: 2000,
        };

        this.http.post(`https://staging.ai.flowrms.com/vector/query`, JSON.stringify(payload), {
            headers: headers,
            responseType: 'text',
            observe: 'events',
            reportProgress: true,
        }).pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (event: HttpEvent<string>) => {

                    if (event.type === HttpEventType.DownloadProgress) {
                        this.handleStream((
                            event as HttpDownloadProgressEvent
                        ).partialText);
                    }

                },
                complete: () => {
                    this.voiceRecognition.speak(this.currentMessage);
                }
            });
        this.scrollToBottom();
    }

    handleStream(data: string) {
        console.log('stuff');
        const lines = data.split('\n');
        lines.forEach((line, index) => {
            if (line.startsWith('data:')) {

                const jsonData = JSON.parse(line.substring(5)); // Remove 'data:' prefix
                if (jsonData.id != 'context' && jsonData.id != 'final' && jsonData.content != '' && jsonData.content != null) {

                    if (this.lineArrays.some(element => element === index)) {
                        return;
                    } else {

                        this.lineArrays.push(index);
                        this.currentMessage += jsonData.content;

                        if (jsonData.id !== this.currentMessageId) {
                            // New message started
                            this.currentMessageId = jsonData.id;
                            this.messages.push({id: jsonData.id, message: jsonData.content, type: 'agent'});

                        } else {
                            // Append to the existing message
                            const existingMessage = this.messages.find(m => m.id === jsonData.id);
                            if (existingMessage) {
                                existingMessage.message += jsonData.content;

                            }
                        }
                        this.scrollToBottom();
                    }
                }
            }
        });
    }

    startRecording() {
        this.voiceRecognition.speak('');
        this.isUserSpeaking = true;
        this.voiceRecognition.start();
        this.finalTranscript = '';  // Clear previous text
        this.interimTranscript = '';

    }

    stopRecording() {
        this.isUserSpeaking = false;
        this.voiceRecognition.stop();
        this.sendMessage();
        this.userInput = '';
    }

    private updateUserInput(transcript: string, isFinal: boolean) {
        if (isFinal) {
            // Append final results to the final transcript and clear interim results
            this.finalTranscript += transcript + ' ';
            this.interimTranscript = '';
            this.userInput = this.finalTranscript;
        } else {
            // Update interim results for real-time feedback
            this.interimTranscript = transcript;
            this.userInput = this.interimTranscript;
        }
    }

    shuffleHints(hints: any[]): any[] {
        return hints.sort(() => 0.5 - Math.random());
    }

    /**
     * Triggers the toggle of the parent sidebar drawer
     *
     */
    toggleDrawer(): void {
        this.drawerToggle.emit(true);
        this.messages = [];
    }

    formatMessage(text: string): string {
        // Formatting bold text
        let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong style="font-size: larger;">$1</strong>');

        // Formatting list items
        formattedText = formattedText.replace(/^- (.*)/gm, '<li>$1</li>');
        formattedText = `<ul>${formattedText}</ul>`; // Wrap with <ul> to ensure proper list display

        return this.sanitizer.sanitize(SecurityContext.HTML, formattedText) || '';
    }


}

