/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [];

export const staticNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboard',
        title   : 'Dashboard',
        subtitle: 'Main Dashboard',
        type    : 'basic',
        icon    : 'heroicons_outline:home',
        link    : '/dashboard',
        roles   : [],
    },
    {
        id      : 'quotes',
        title   : 'Quotes',
        subtitle: 'Flow Quotes',
        type    : 'basic',
        icon    : 'heroicons_outline:currency-dollar',
        link    : 'quotes/list',
        roles   : [],
    },
    {
        id      : 'orders',
        title   : 'Orders',
        subtitle: 'Flow Orders',
        type    : 'basic',
        icon    : 'heroicons_outline:shopping-cart',
        link    : '/orders/list',
        roles   : [],
    },
    {
        id      : 'invoices',
        title   : 'Invoices',
        subtitle: 'Flow Invoices',
        type    : 'basic',
        icon    : 'mat_outline:receipt_long',
        link    : '/invoices/list',
        roles   : [],

    },
    {
        id      : 'checks',
        title   : 'Checks',
        type    : 'collapsable',
        icon    : 'heroicons_outline:credit-card',
        roles   : [],
        children: [
            {
                id   : 'checks.list',
                title: 'Commission Checks',
                type : 'basic',
                link : '/checks/list'
            },
            {
                id   : 'checks.report',
                title: 'Commission Report',
                type : 'basic',
                link : '/checks/reports'
            },
        ]
    },
    {
        id      : 'products',
        title   : 'Products',
        type    : 'collapsable',
        icon    : 'heroicons_outline:clipboard-document-check',
        roles   : [],
        children: [
            {
                id   : 'products.list',
                title: 'Products',
                type : 'basic',
                link : 'products/list'
            },
            {
                id   : 'products.categories',
                title: 'Categories',
                type : 'basic',
                link : 'products/categories',
            },
            {
                id   : 'products.uoms',
                title: 'Units Of Measure',
                type : 'basic',
                link : 'products/units-of-measure',
            },
        ]
    },

    {
        id      : 'customers',
        title   : 'Customers',
        subtitle: 'Flow Customers',
        type    : 'basic',
        icon    : 'heroicons_outline:users',
        link    : '/customers/list',
        roles   : [],
    },
    {
        id      : 'factories',
        title   : 'Factories',
        subtitle: 'Flow Factories',
        type    : 'basic',
        icon    : 'heroicons_outline:building-office-2',
        link    : '/factories/list',
        roles   : [],
    },
    {
        id      : 'uploads',
        title   : 'Upload Center',
        subtitle: 'Automate Data Entry',
        type    : 'basic',
        icon    : 'heroicons_outline:arrow-up-on-square-stack',
        link    : '/uploads',
        roles   : [],
    },
    {
        id      : 'reports',
        title   : 'Reports',
        subtitle: 'Reports',
        type    : 'basic',
        icon    : 'heroicons_outline:chart-bar',
        link    : '/reports',
        roles   : [],
    },
    {
        id      : 'admin',
        title   : 'Admin Settings',
        subtitle: 'Site Administration',
        type    : 'basic',
        icon    : 'admin_panel_settings',
        link    : '/admin',
        roles   : [],
    }
];
