<div class="relative flex flex-col w-full h-full overflow-hidden">
    <!-- Content -->
    <div class="flex flex-col h-full">
        <!-- Sticky Titles -->
        <div class="sticky top-0 z-10 flex flex-row w-full items-center bg-accent-800">
            <!-- Title -->
            <div class="flex flex-row w-full items-center p-6">
                <div class="text-xl leading-6 font-bold text-left w-full flex flex-row items-center text-white">
                    <img src="assets/images/logo/Flow-o.svg" class="h-6 w-6 mr-1" alt="flow logo"/>
                    flowBot
                    <span class="bg-primary rounded px-2 py-1 text-xs leading-none text-white ml-3">Beta</span>
                </div>
                <div class="flex flex-row gap-1">
                    <!-- Buttons -->
                    <button mat-icon-button [matTooltip]="'Toggle Fullscreen'">
                        <mat-icon class="text-white" [svgIcon]="'heroicons_outline:arrows-pointing-out'"></mat-icon>
                    </button>
                    <button mat-icon-button [matTooltip]="'Open flowBot in chat mode'">
                        <mat-icon class="text-white"
                                  [svgIcon]="'heroicons_outline:arrow-top-right-on-square'"></mat-icon>
                    </button>
                    <button mat-icon-button [matTooltip]="'flowBot Settings'" [matMenuTriggerFor]="flowBotSettings">
                        <mat-icon class="text-white" [svgIcon]="'heroicons_outline:cog'"></mat-icon>
                    </button>
                    <mat-menu #flowBotSettings="matMenu" class="p-6 bg-accent-800 text-white">
                        <span class="block">Decide how flowBot should work for you.</span>
                        <mat-slide-toggle class="mt-4"><span class="text-white">Speech to Text</span></mat-slide-toggle>
                        <mat-slide-toggle class="mt-4 "><span class="text-white">Read Responses</span>
                        </mat-slide-toggle>
                    </mat-menu>
                    <button mat-icon-button (click)="toggleDrawer()" [matTooltip]="'Close flowBot'">
                        <mat-icon class="text-white"
                                  [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <!-- Messages -->
        <div class="flex flex-col flex-1 overflow-auto pb-28" #messagesContainer>
            <div class="w-full p-6 space-y-6">
                <div *ngIf="messages.length > 0; else noMessagesTemplate">
                    <div *ngFor="let message of messages" class="flex flex-col gap-8 mb-2">
                        <ng-container [ngTemplateOutlet]="message.type === 'user' ? userMessageTemplate : agentMessageTemplate" [ngTemplateOutletContext]="{data: message}"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Actions -->
    <div class="sticky bottom-0 w-full bg-accent-800 px-6 py-8 space-x-3">
        <div class="border-gray-50 border rounded w-full py-2 px-2 flex items-center">
            <div class="justify-start">
                <button mat-icon-button>
                    <mat-icon [svgIcon]="'mat_solid:attach_file'" class="icon-size-5"></mat-icon>
                </button>
            </div>
            <input #inputField autofocus (keyup.enter)="sendMessage()" [(ngModel)]="userInput"
                   class="text-white grow ml-2"
                   placeholder="Type your message..." type="text">
            <div class="justify-end">
                <button mat-icon-button *ngIf="!isUserSpeaking" (click)="startRecording()">
                    <mat-icon [svgIcon]="'mat_outline:mic'" class="icon-size-5"></mat-icon>
                </button>
                <button mat-icon-button *ngIf="isUserSpeaking" (click)="stopRecording()">
                    <mat-icon [svgIcon]="'mat_outline:mic_off'" class="icon-size-5"></mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>


<!--User message template-->
<ng-template #userMessageTemplate let-msg="data">
    <div class="w-full text-white" dir="auto" data-testid="conversation-turn-2" data-scroll-anchor="false">
        <div class="py-2 px-3 text-base md:px-4 m-auto lg:px-1 xl:px-5">
            <div
                class="mx-auto flex flex-1 gap-3 text-base md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]">
                <div class="flex-shrink-0 flex flex-col relative items-end">
                    <div>
                        <div class="pt-0.5">
                            <div
                                class="flex h-8 w-8 items-center justify-center overflow-hidden rounded-full">
                                <div class="">
                                    <span class="rounded-full p-3 bg-primary text-white text-small">{{userInitials | uppercase}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative flex w-full min-w-0 flex-col">
                    <div class="font-semibold select-none">You</div>
                    <div class="flex-col gap-1 md:gap-3">
                        <div class="flex flex-grow flex-col max-w-full">
                            <div data-message-author-role="user" data-message-id=""
                                 class="min-h-[20px] text-message flex flex-col items-start whitespace-pre-wrap break-words overflow-x-auto gap-3">
                                <div class="">
                                    <span class="text-white">{{ msg.message }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!--flowBot message template-->
<ng-template #agentMessageTemplate let-msg="data">
    <div class="w-full text-white" dir="auto" data-testid="conversation-turn-2" data-scroll-anchor="false">
        <div class="py-2 px-3 text-base md:px-4 m-auto lg:px-1 xl:px-5">
            <div
                class="mx-auto flex flex-1 gap-3 text-base md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]">
                <div class="flex-shrink-0 flex flex-col relative items-end">
                    <div>
                        <div class="pt-0.5">
                            <div
                                class="flex h-8 w-8 items-center justify-center overflow-hidden rounded-full">
                                <div class="">
                                    <ng-lottie [options]="options" class="flex justify-center" width="36px"></ng-lottie>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative flex w-full min-w-0 flex-col">
                    <div class="font-semibold select-none">flowBot</div>
                    <div class="flex-col gap-1 md:gap-3">
                        <div class="flex flex-grow flex-col max-w-full">
                            <div data-message-author-role="user" data-message-id=""
                                 class="min-h-[20px] text-message flex flex-col items-start whitespace-pre-wrap break-words overflow-x-auto gap-3">
                                <div class="">
                                    <span class="text-white">{{ msg.message }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!--No message template-->
<ng-template #noMessagesTemplate>
    <div class="flex flex-col w-full h-full pt-16">
        <div class="flex justify-center items-center text-white">
            <div class="flex h-full flex-col items-center justify-center text-token-text-primary">
                <ng-lottie [options]="options" class="flex justify-center" width="72px"></ng-lottie>
                <div class="mb-5 text-2xl font-medium">What can flowBot do for you?</div>
            </div>
        </div>
        <div class="w-full grid grid-cols-1 sm:grid-cols-2 gap-6 text-white mt-8">
            <div *ngFor="let hint of displayedHints" class="border rounded-xl p-6 flex flex-col hover:bg-accent-700 hover:cursor-pointer" matTooltip="Ask flowBot" (click)="sendMessage(hint.subTitle)">
                <span class="text-lg">{{ hint.title }}</span>
                <span class="text-sm text-secondary">{{ hint.subTitle }}</span>
            </div>
        </div>
    </div>

</ng-template>
