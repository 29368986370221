import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {NotifierComponent} from './shared/components/notifications/notifier/notifier.component';
import {DatePipe} from "@angular/common";
import { MatDialog } from '@angular/material/dialog';
import { Subject, catchError, first, take, takeUntil, throwError } from 'rxjs';
import { UpdateAppDialogComponent } from './shared/components/update-app-dialog/update-app-dialog.component';
import { AppService } from './app.service';
import { AuthService } from './core/auth/auth.service';
import { UserService } from './modules/admin/users/users.service';
import { CurrentUserPermission, MinifiedPermissions, PermissionByResource } from './modules/admin/users/user.type';
import { staticNavigation } from './mock-api/common/navigation/data';
import { NavigationService } from './core/navigation/navigation.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { AuthUtils } from './core/auth/auth.utils';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, NotifierComponent],
    providers: [DatePipe]
})

export class AppComponent implements OnInit, OnDestroy {

    deployedVersion = null;
    private _unsubscribeAll: Subject<any>;
    alwaysAllowedRoutes = [];
    // alwaysAllowedRoutes = ['dashboard'];

    constructor(
        public dialog: MatDialog,
        private _appService: AppService,
        private _authService: AuthService,
        private _userService: UserService,
        private _navigationService: NavigationService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _router: Router,
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        if (this._authService.isLoggedIn()) {
            this.getRoutesPermissions();
            this.getCurrentFlowVersion(true)
            setInterval(() => this.getCurrentFlowVersion(), 60 * 60 * 1000);
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    verifyOutdatedAppVersion(): void {
        const currentVersionStored = localStorage.getItem('flow_version');
        if (currentVersionStored !== this.deployedVersion) {
            this.openUpdateAppDialog(currentVersionStored);
        }
    }

    openUpdateAppDialog(currentVersionStored): void {
        const dialog = this.dialog.open(UpdateAppDialogComponent, {
            autoFocus: true,
            data: {
                currentVersionStored: currentVersionStored,
                deployedVersion: this.deployedVersion
            },
            panelClass: ['md:min-w-140', 'lg:w-1/2', 'update-app-dialog']
        });
        dialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(async (result) => {
                if (result) {
                    localStorage.setItem('flow_version', this.deployedVersion);
                    location.reload();
                }
            });
    }

    getCurrentFlowVersion(firstLoad = false): void {
        this._appService.getCurrentFlowVersion()
        .pipe(take(1))
        .subscribe({
            next: (data) => {
                if (data?.version) {
                    this.deployedVersion = data.version;
                    if (firstLoad) {
                        localStorage.setItem('flow_version', this.deployedVersion);
                    } else {
                        this.verifyOutdatedAppVersion();
                    }
                }
            }, error: (error) => {
                console.log('Error Getting Flow Current Version');
            }

        });
    }

    getRoutesPermissions(): void {
        this._appService.findCurrentResources()
            .pipe(takeUntil(this._unsubscribeAll),
            catchError((error) => {
                return throwError(error);
            }))
            .subscribe((data) => {
                if (data) {
                    const minimizedPermissions = this.minimizeRoutesPermissions(data);
                    this._authService.setRoutesPermissions(minimizedPermissions);
                    console.log('minimizedPermissions', minimizedPermissions)
                    this.populateDynamicRolesForNavigation();
                    this.verifyVisibleMenus();
                    this._navigationService.updateNavigation({default: staticNavigation});
                    this._fuseSplashScreenService.hide();
                }
            });
    }

    minimizeRoutesPermissions(response: CurrentUserPermission[]): MinifiedPermissions {
        const userRole = this._authService.getUserRole();
        console.log('userRole', userRole);

        const resourcePrivilegeRoleMap = {};

        response.forEach(resourceData => {
            const resource = resourceData.resource;
            const privileges = resourceData.privileges;

            // Map resource to its privileges
            resourcePrivilegeRoleMap[resource] = {};
            ['view', 'edit', 'delete'].forEach(defaultPrivilege => {
                let roleArray = ['administrator', 'owner', 'super-admin'];
                if (userRole !== 'administrator' && userRole !== 'owner' && userRole !== 'super-admin') {
                    roleArray.push(userRole);
                }

                if (privileges.includes(defaultPrivilege)) {
                    resourcePrivilegeRoleMap[resource][defaultPrivilege] = roleArray;
                } else {
                    resourcePrivilegeRoleMap[resource][defaultPrivilege] = ['administrator', 'owner', 'super-admin'];
                }
            });
        });

        return resourcePrivilegeRoleMap;
    }

    populateDynamicRolesForNavigation(): void {
        this._authService.routesPermissions$.pipe(
            take(1),
        ).subscribe(value => {
            for (const key of Object.keys(value)) {
                const roles = this._authService.getAuthorizedRoles(key, 'view');
                const navigationItem = staticNavigation.find(item => item.id === key);
                if (navigationItem) {
                    navigationItem.roles = roles;
                }
            }
        });
    }

    verifyVisibleMenus(): void {
        if (staticNavigation) {
            for (let index = staticNavigation.length - 1; index >= 0; index--) {
                const item = staticNavigation[index];
                // Adding admin access to all resources that isn't possible to manipulate the conditions
                if (item.roles?.length === 0) {
                    item['roles'].push('administrator', 'owner', 'super-admin');
                };
                const hasRolesResult = this._authService.hasRoles(item.roles);

                // Hard-coding the dashboard menu for outside-rep role even without the view access
                const userRole = this._authService.getUserRole();
                if (userRole === 'outside-rep' || userRole === 'inside-rep') {
                    this.alwaysAllowedRoutes.push('dashboard')
                }

                // Not allowing to remove the routes that should be always visible;
                const isAlwaysAllowedRoute = this.alwaysAllowedRoutes.find(route => route === item.id);

                if (!hasRolesResult && !isAlwaysAllowedRoute) {
                    staticNavigation.splice(index, 1);
                }
            }
        }
    }
}
