<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-20">
            <img
                class="w-8"
                src="assets/images/logo/logo.png"
                alt="Logo image">
        </div>
    </ng-container>
    <!--<ng-container fuseVerticalNavigationContentFooter>
        &lt;!&ndash; Logo &ndash;&gt;
        <div class="flex items-center justify-center object-bottom p-2">
            <span class="font-medium text-secondary">flowRMS &copy; {{currentYear}}</span>
        </div>
    </ng-container>-->
    <ng-container fuseVerticalNavigationFooter>
        <div class="flow-version flex items-center justify-center object-bottom p-2">
            <span class="minified font-medium text-secondary">{{ version }}</span>
            <a routerLink="/changelog" class="expanded font-medium text-secondary">Version {{ version }}</a>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <mat-drawer-container class="flex-auto h-full w-full" >
        <mat-drawer #flowBot position="end" class="bg-accent-800 rounded-l-2xl w-full md:w-1/2" disableClose>
            <app-flowbot-dialog (drawerToggle)="flowBot.toggle()" [searchInput]="flowBotSearch"></app-flowbot-dialog>
        </mat-drawer>
        <mat-drawer-content class="flex flex-col overflow-hidden">
            <!-- Header -->
            <div
                class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
                <div class="flex items-center pr-2 space-x-2">
                    <!-- Navigation toggle button -->
                    <button
                        mat-icon-button
                        (click)="toggleNavigation('mainNavigation')">
                        <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
                    </button>
                    <!-- Navigation appearance toggle button -->
                    <button
                        class="hidden md:inline-flex"
                        mat-icon-button
                        (click)="toggleNavigationAppearance()">
                        <mat-icon [svgIcon]="'heroicons_outline:arrows-right-left'"></mat-icon>
                    </button>
                </div>
                <!-- Components -->
                <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">

                    <search [appearance]="'bar'" (drawerToggle)="initiateFlowBot($event);"></search>
                    <fuse-fullscreen class="hidden md:block" ></fuse-fullscreen>
                    <user></user>
                </div>
            </div>

            <!-- Content -->
            <div class="flex flex-col flex-auto">

                <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
                <router-outlet *ngIf="true"></router-outlet>


            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>

